/*
* ©2016 Matthew Bergsma, Gravit Digital. All Rights Reserved.
*  May not be redistrubted without permission
*/
(function (Backbone, _, $, videojs, Drupal, window, document) {

	// To understand behaviors, see https://drupal.org/node/756722#behaviors	
	Drupal.behaviors.my_custom_behavior = {
	  attach: function(context, settings) {	    
	    // init components
		var menu = new MenuView();

		if ($('.lesson-env')[0]) {
			var l = new PlayerView();
		}

		$(".entity-paragraphs-item.horizontal").each(function() {
    		var hv = new HorizontalView( { el: $(this) } );
		});	   

		$('.node-course-lesson.node-teaser').click(function(e) {
			var $t = $(this);
			e.preventDefault();
			var href = $t.find('.teaser-title a').attr('href');
			window.location = href;
		});

		$('[data-iframe-embed]').click(function(e) {
			e.preventDefault();
			var $t = $(this);		

			var url = $(this).attr('data-iframe-embed');
			if (url != '') {
				var $iframe = $('<div class="responsive-embed"></div>').hide();
				
				var $p = $t.parents('.entity-paragraphs-item');
				var y = $p.offset().top;
				
				$p.before($iframe);
				$iframe.html('<iframe seamless allowfullscreen frameborder="0" src="'+url+'" />');
				$iframe.slideDown(333);
				
				$t.attr('data-iframe-embed', '');

				$('html, body').animate({scrollTop: y}, 333);
			}
		});
	  }
	};	
	






	/* ------------------------------------------------------------------------
	 * MenuView
	 */	 
	var MenuView = Backbone.View.extend({

		el: "#site-header",		
		events: {
			'click .menu-toggle': '_click'			
		},
		model: new Backbone.Model({active:false}),

		initialize: function(options) {
			this.options = options;
      		this.listenTo(this.model, 'change', this.render);		
			_.bindAll(this, '_click', 'render');	
		},

		render: function() {
			var active = this.model.get('active');	
			if (active) {
				$('#site-header, #site-footer').addClass('active');
				this.$('.menu-toggle').html(' X');
			} else {
				$('#site-header, #site-footer').removeClass('active');
				this.$('.menu-toggle').html('☰');
				
			}
		},

		_click: function() {
			var active = this.model.get('active');				
			this.model.set({active:!active});
		}		
	});



	/* ------------------------------------------------------------------------
	 * HorizontalArticleView
	 */	 
	var HorizontalView = Backbone.View.extend({

		//el: ".entity-paragraphs-item.horizontal",		
		events: {
			'click .button-next': '_next',
			'click .button-prev': '_prev',
			'click .button-load': '_clickLoad'			
		},
		length: 0,
		backgrounds: null,		
		

		initialize: function(options) {
			this.options = options;
			var v = this;

			_.bindAll(this, '_next', '_prev', '_load', '_clickLoad');

			v.model = new Backbone.Model();
      		v.listenTo(this.model, 'change', this.render);

			v.$el.addClass('js-enabled');			
			
			if (!this.$('.button-load')[0]) {
				v._load();
			}			
		},

		_clickLoad:function(e) {
			e.preventDefault();
			$(e.currentTarget).fadeOut(333);
			this._load();
			this.model.set({index:1});
		},

		_load: function(e) {
			if (this.$el.find('>.bg')[0]) {
				this.$el.prepend('<div class="horizontal-nav"><div class="button button-prev">❮</div><div class="button button-next">❯</div></div>');
			} else {
				this.$el.prepend('<div class="bg"></div><div class="horizontal-nav"><div class="button button-prev">❮</div><div class="button button-next">❯</div></div>');
			}
			
			this.length = this.$('.entity-paragraphs-item').length;	
			this.backgrounds = new Array();			

			var ba = this.backgrounds;
			this.$('.entity-paragraphs-item').each(function() {
				var bg = $(this).find('.bg').css('background-image');
				if (bg == 'none' || bg == '' || bg == undefined) {
					bg = $(this).css('background-color');
					if (bg == 'transparent' || bg == '' || bg == 'none') {
						bg = '#fff';
					}
				}				
				ba.push(bg);
				$(this).find('.bg').hide();//.css('background-image','');
			});	
			this.model.set({index:0});
		},

		render: function() {
			var i = this.model.get('index');
			var v = this;	
			
			// var $lastImg = this.$('.entity-paragraphs-item:visible');							
			//var $newImg = this.$('.entity-paragraphs-item:eq('+i+')');
			//var x = $newImg.position().left;
			//console.log($newImg.position());
			
			//this.$('.paragraphs-items-field-body-components').stop().animate({scrollLeft:x},250);
			var bg = this.backgrounds[i];
			var $bg = this.$('>.bg');

			$bg.stop().fadeTo(300, 0, function() {
				$bg.css('background', bg).stop().fadeTo(300, 1);				
			});
			v.$('.paragraphs-items-field-body-components').stop().delay(150).animate({left:-(i*100)+'%'},350);
			/*
			// determine direction
			if (i > this.model.previous('index')) {
				//console.log('fwd');
			} else {
				//console.log('back');
			}

			$lastImg.stop(true, true).fadeOut(250, function() {				
				$newImg.fadeIn(333);				
			});
			*/
		},

		_prev: function(e) {
			var i = this.model.get('index') - 1;
			if (i<0) {
				i = this.length-1;
			}			
			this.model.set({index:i});
		},

		_next: function(e) {
			var i = this.model.get('index') + 1;
			if (i>this.length-1) {
				i = 0;
			}			
			this.model.set({index:i});
		}		
	});	




	/* ------------------------------------------------------------------------
	 * MenuView
	 */	 
	var PlayerView = Backbone.View.extend({

		el: ".lesson-env",	
		player: null,	
		events: {
			'click .field-name-field-lesson-videos li': '_click'			
		},		
		model: new Backbone.Model({lesson:null}),


		initialize: function(options) {
			this.options = options;
      		this.listenTo(this.model, 'change', this.render);
      		var $p = this.$('.player-env');
      		var $v = $p.find('video');
      		if (!$v[0]) {
      			$p.append('<video controls playsinline></video>');
      		}
      		this._initPlayer($v[0]);
			_.bindAll(this, '_click', 'render', '_initPlayer', 'next', 'prev');	
		},


		_initPlayer(videoElement) {
			var view = this;
			$(videoElement).addClass('video-js vjs-skin-default vjs-static-controls');
			var src = $(videoElement).find('source').attr('src');

			var p = view.player = videojs(videoElement, 
			{
			  controls: true,
			  autoplay: true,
			  ratio: '960:390',
			  fluid: true,
			  skin: 'default',
			  children: [
			      'bigPlayButton',
			      'controlBar'
			    ],
			    controlBar: {
			        fullscreenToggle: false
			      }
			},
			function() {
				$(videoElement).wrap('<div class="video-crop" />');
				//view.player.src(src);				
			});

			
			//this.$('.vjs-control-bar').prepend('<button class="vjs-control vjs-button vjs-previous-control" />');
			
			var videoJsButtonClass = videojs.getComponent('Button');
			var PrevButton = videojs.extend(videoJsButtonClass, {
			  constructor: function() {
			    videoJsButtonClass.call(this, p);
			  }, 
			  handleClick: function(){
			    view.prev();
			  }
			});
			var NextButton = videojs.extend(videoJsButtonClass, {
			  constructor: function() {
			    videoJsButtonClass.call(this, p);
			  }, 
			  handleClick: function(){
			    view.next();
			  }
			});

			var pInstance = this.player.controlBar.addChild(new PrevButton());
			pInstance.addClass("vjs-prev");

			var nInstance = this.player.controlBar.addChild(new NextButton());
			nInstance.addClass("vjs-next");
			
			/*
			p.on('ready', function() {
				alert('ready');
			  this.play();
			});
			*/
		},

		next: function() {
			var $l = this.$('.field-name-field-lesson-videos li.active');
			var $n = $l.next();
			if (!$n[0]) {
				$n = this.$('.field-name-field-lesson-videos li').first();
			}
			//this.model.set({lesson:$n.data('vid'), loop:lp});
			$n.trigger('click');
		},

		prev: function() {
			var $l = this.$('.field-name-field-lesson-videos li.active');
			var $n = $l.prev();
			if (!$n[0]) {
				$n = this.$('.field-name-field-lesson-videos li').last();
			}
			//this.model.set({lesson:$n.data('vid'), loop:lp});
			$n.trigger('click');
		},

		render: function() {
			// LAZY WAY TO TRACK VIEWS
			var nid = this.$el.data('nid');
			if (nid) {
				var $flagWatched = this.$('.flag-user-views .flag-action');
				if ($flagWatched[0]) {
					$flagWatched.trigger('click');
				}
			}

			var l = this.model.get('lesson');
			var lp = this.model.get('loop');

			// determine folder for lesson selected based on DOM
			var $selected = this.$el.find('[data-vid="'+l+'"]');
			var folder = this.$el.data('f') ? this.$el.data('f') : $selected.parents('.field-name-field-lesson-videos').find('[data-f]').data('f');
			
			//	check for images to load
			var $imgs = $selected.parents('li').find('.field-name-field-lesson-images').html();
			if (!$imgs) {
				$imgs = '';
			} else {
				$imgs = '<div>'+$imgs+'</div>';
			}
			this.$el.find('.player-images').html($imgs);
			
			var vid = Drupal.settings.gravit_course.cdn + folder +'/'+l+'.mp4';
			this.player.src(vid);
			if (lp) {
				this.player.options.loop = true;
			} else {
				this.player.options.loop = false;
			}
			/*
			this.player.ready(function() {
			  this.player.play();
			});
			*/

			
			//$v[0].load();
			//$v[0].play();

			this.$('li.active').removeClass('active');
			$selected.parents('li').addClass('active');
			// $p.delay(1000).css('height','');
			//.html('<source type="vid/mp4" src="'+l+'" />')[0].load();
		},

		_click: function(e) {
			if ($(e.srcElement).hasClass('flag')) {
				return;
			}
			var $e = $(e.currentTarget).find('.lv'); 
			var v = $e.data('vid');
			var lp = ($e.data('type') == 'loop') ? true : false;	
			if (v) {
				this.model.set({lesson:v, loop:lp});				
			}			
		}		
	});	


	


})(Backbone, _, jQuery, videojs, Drupal, this, this.document);